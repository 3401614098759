import React from "react"
import RichDrop from "./RichDrop"
import { Link } from "gatsby"


class Menu extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      width: 1200,
      isExpanded: false
    }
  }

  componentDidMount() {
    this.handleWindowSizeChange()
    window.addEventListener("resize", this.handleWindowSizeChange)
  }


  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange)
  }

  handleWindowSizeChange = () => {
    this.setState({
      width: window.innerWidth,
      isExpanded: this.state.isExpanded
    })
  }

  toggleExpansion(val) {
    this.setState({
      width: window.innerWidth,
      isExpanded: val
    })
  }

  render() {

    return (
      <div>
        <div className="topmenu" style={{
          display: `${this.state.width > 991 ? "block" : "none"}`
        }}>
          <div className="social">
            <a href="https://www.facebook.com/bproservicerus" target="_blank" className="fb"><span
              className="sr-only">Facebook</span></a>
            <a href="https://www.instagram.com/b_pro_service" target="_blank" className="ig"><span
              className="sr-only">Instagram</span></a>
            <a href="https://www.youtube.com/user/BONGARDBoulange" target="_blank" className="yt"><span
              className="sr-only">YouTube</span></a>
          </div>
          <div className="link">
            <ul id="menu-adipso-menu-haut" className="navbar-nav mr-auto">
              <li itemScope="itemScope" itemType="https://www.schema.org/SiteNavigationElement"
                  id="menu-item-232"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-232 nav-item">
                <Link to={"/"} className="nav-link" title="О нас">О нас</Link>
              </li>
              <li itemScope="itemScope" itemType="https://www.schema.org/SiteNavigationElement"
                  id="menu-item-254"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-254 nav-item">
                <Link to={"/contacts"} className="nav-link" title="Контакты">Контакты</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="megamenu">
          <div className="mainmenu">
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
              <Link to={"/"} className="navbar-brand" title="Bongard - Оборудование для пекарен">
                <img src="/images/b_logo.png"
                     alt="Bongard - Оборудование для пекарен" />
              </Link>
              <div id="mainmenu"
                   className={"collapse navbar-collapse " + `${this.state.isExpanded && (this.state.width <= 991) ? " show" : ""}`}>
                <div className="mobile_pg">
                  <ul id="menu-adipso-menu-principal" className="navbar-nav mr-auto">
                    <li itemScope="itemScope"
                        itemType="https://www.schema.org/SiteNavigationElement"
                        id="menu-item-217"
                        className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children dropdown menu-item-217 nav-item">
                      <a title="Продукция" href="#" aria-haspopup="true"
                         aria-expanded="false" className="dropdown-toggle nav-link"
                         id="menu-item-dropdown-217"
                         onClick={() => this.toggleExpansion(!this.state.isExpanded)}>Продукция</a>
                      {(this.state.width <= 991) ?
                        <RichDrop isExpanded={this.state.isExpanded} /> : ""}
                    </li>
                    <li itemScope="itemScope"
                        itemType="https://www.schema.org/SiteNavigationElement"
                        id="menu-item-229"
                        className="menu-item menu-item-type-post_type_archive menu-item-object-boulangerie menu-item-229 nav-item">
                      <Link to={"/your-bakery"} className="nav-link" title="Ваша пекарня">Ваша
                        пекарня</Link>
                    </li>
                  </ul>
                  <Link to={"/contact-us"} className="btn">Задайте вопрос</Link>
                  <div className="spe_mobile"></div>
                </div>
              </div>
              <button className={"navbar-toggler " + `${this.state.isExpanded ? "collapsed" : ""}`}
                      type="button" data-target="#mainmenu" aria-expanded="false"
                      aria-controls="mainmenu" aria-label="Меню"
                      onClick={() => this.toggleExpansion(!this.state.isExpanded)}>
                            <span className="navbar-toggler-icon">
                                <span /><span /><span />
                            </span>
              </button>
            </nav>
          </div>
          {(this.state.width > 991 && this.state.isExpanded) ?
            <RichDrop isExpanded={this.state.isExpanded} /> : ""}
        </div>
      </div>
    )
  }


}

export default Menu
