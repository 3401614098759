import React from 'react';
import {Link} from "gatsby";

const RichDrop = (isExpanded) => {
    return (
        <div className="richdrop" data-rd="menu-item-217"
             style={{
                 visibility: 'visible',
                 display: isExpanded ? "contents" : "none"
             }}>
            <div>
                <div>
                    <div className="children">
                        <span className="h5"><Link to={"/dough-preparation"}>Приготовление теста</Link></span>
                        <div className="subchildren">
                            <ul>
                                <li><Link to={"/dough-preparation/water-cooler"}>Охладители воды</Link></li>
                                <li><Link to={"/dough-preparation/water-meter"}>Дозатор воды</Link></li>
                                <li><Link to={"/dough-preparation/spiral-mixers"}>Спиральные миксеры</Link></li>
                                <li><Link to={"/dough-preparation/bowl-tilter"}>Дежеопрокидыватели</Link></li>
                                <li><Link to={"/dough-preparation/planetary-mixer"}>Планетарные миксеры</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="children">
                        <span className="h5"><Link to={"/dough-division-and-moulding"}>Формовка теста</Link></span>
                        <div className="subchildren">
                            <ul>
                                <li><Link to={"/dough-division-and-moulding/dough-division"}>Деление теста</Link></li>
                                <li><Link to={"/dough-division-and-moulding/pre-rounding-and-rounders"}>Округление теста</Link></li>
                                <li><Link to={"/dough-division-and-moulding/intermediate-proofing"}>Предварительная расстойка</Link></li>
                                <li><Link to={"/dough-division-and-moulding/moulders"}>Формовка тестозаготовок</Link></li>
                                <li><Link to={"/dough-division-and-moulding/paneotrad"}>Делитель тестоформовщик</Link></li>
                                <li><Link to={"/dough-division-and-moulding/dough-sheeter"}>Тестораскаточные машины</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="children">
                        <span className="h5"><Link
                            to={"/fermentation-and-conservation"}>Ферментация и консервация</Link></span>
                        <div className="subchildren">
                            <ul>
                                <li><Link to={"/fermentation-and-conservation/dough-retarding-and-proofing"}>Контроллеры ферментации</Link></li>
                                <li><Link to={"/fermentation-and-conservation/conservation-units"}>Холодильные камеры</Link></li>
                                <li><Link to={"/fermentation-and-conservation/blast-freezers"}>Камеры заморозки</Link></li>
                                <li><Link to={"/fermentation-and-conservation/walk-in-cold-room"}>Камеры охлаждения</Link></li>
                                <li><Link to={"/fermentation-and-conservation/refrigerated-workbench"}>Охлаждаемые рабочие места</Link></li>
                                <li><Link to={"/fermentation-and-conservation/bread-cooler"}>Охладители хлеба</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="children">
                        <span className="h5"><Link to={"/baking"}>Выпечка</Link></span>
                        <div className="subchildren">
                            <ul>
                                <li><Link to={"/baking/rack-oven"}>Ротационные печи</Link></li>
                                <li><Link to={"/baking/deck-oven"}>Подовые печи</Link></li>
                                <li><Link to={"/baking/accessories-for-deck-oven"}>Аксессуары для подовых печей</Link></li>
                                <li><Link to={"/baking/convection-oven"}>Конвекционные печи</Link></li>
                                <li><Link to={"/baking/modular-deck-oven"}>Модульные печи</Link></li>
                                <li><Link to={"/baking/combined-modular-and-convection-oven"}>Комбинированные печи</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RichDrop;
