import React from "react"
import { Link } from "gatsby"

const Footer = () => {
  return (
    <footer>
      <div>
        <div className="triple">
          <div>
            <ul>
              <li id="menu-item-1752"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home menu-item-1752">
                <Link to={`/`}>Главная</Link>
              </li>
              <li id="menu-item-1751"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1751">
                <a href="#">О нас</a>
              </li>
              <li id="menu-item-1819"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1819">
                <a href="#">Наши партнеры</a>
              </li>
              <li id="menu-item-1822"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1822">
                <Link to={"/contacts"}>Контакты</Link>
              </li>
              <li id="menu-item-1822"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1822">
                <Link to={"/contact-us"}>Связаться с нами</Link>
              </li>
            </ul>
            &nbsp;
            <a href="#" className="ml">Политика приватности</a>&nbsp;
            <a href="#" className="ml">Карта сайта</a>
          </div>
          <div>
            <a href="#">
              <img src="/images/b_logo_w.png" alt="Logo" />
            </a>
          </div>
          <div className="rgt">
            <Link to={"/contact-us"} className="btn">Задайте вопрос</Link>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
